body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  min-height: 100%;
  min-height: 100vh;
}

.heading {
  font-weight: 300;
  color: #dc1a22;
  font-style: unset;
}

h2 {
  font-size: 30px;
  margin-top: 2rem;
  text-align: left;
}
h1 {
  font-size: 35px;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  border: none;
  color: white;
  font-family: "Open Sans", sans-serif;
  border-radius: 0;
  transition: 0.3s ease-in-out;
  font-weight: unset;
  font-size: 11px;
  box-shadow: none;
  width: 80px;
  height: 30px;
  outline: none;
  cursor: pointer;
}

.btn-red {
  background-color: rgb(220, 26, 34);
}

.restart-btn {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.headerLink {
  color: #4d4d4d;
  padding-right: 1rem;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}
.headerLink:hover {
  color: rgb(220, 26, 34);
}
@media screen and (max-width: 720px) {
  .headerLink {
    display: grid;
    font-size: 13px;
    padding: unset;
  }
}

footer {
  min-height: 100px;
  background-color: #4d4d4d;
  margin-top: 2rem;
  text-align: center;
  display: table;
  width: 100%;
}
.footerContainer {
  display: table-cell;
  vertical-align: middle;
}
footer ul {
  padding: 0;
  list-style-type: disc;
  margin: 0;
}
footer li {
  display: inline;
  padding-right: 1em;
}
footer a {
  color: white;
  text-decoration: none;
  font-size: 13px;
  transition: 0.3s ease-in-out;
}
footer a:hover {
  color: rgb(220, 26, 34);
}
footer span {
  padding-right: 10px;
  font-weight: bold;
  color: white;
}
.button_box {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

@media only screen and (max-device-width: 768px) {
  .button_box {
    position: relative;
  }
}
